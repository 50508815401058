import { Box, Typography } from "@mui/material";
import React from "react";
  
const Contact = () => {
  return (
    <Box
      sx={{
        width: "90%",
        height: "fit-content",
        textAlign: "center",
        margin: "2rem auto",
        padding: "2rem 0",
        backgroundColor: 'primary.dark',
        background: "linear-gradient(0deg, rgba(244, 249, 255, 0) 0%, #F4F9FF 100%)",
        // backdropFilter: "blur(20px)",
        borderRadius: "20px",
        boxShadow: "0 8px 32px 0 rgb(59 72 255 / 11%)",
      }}
    >
      <Box
        sx={{
          height: "inherit",
          width: "inherit",
          textAlign: "initial",
          margin: "auto",
          // backgroundColor: "#cbe4de33",
          color: "black",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <Typography variant="h4" fontWeight={700} gutterBottom>
          <span style={{ color: "#074DC0" }}>Contact us – </span>care@apik.ai
        </Typography>
        <Typography
          variant="h6"
          sx={{  margin: "auto" }}
          gutterBottom
        >
          Registered address: C-3, First Floor, Pancwati Colony, Adarsh Nagar , New Delhi -110033
        </Typography>
        <Typography
          variant="h6"
          sx={{ margin: "auto" }}
          gutterBottom
        >
          Office Address: 515, Mangalam Paradise Mall, Rohini, sector - 3, New Delhi - 110085
        </Typography>
      </Box>
    </Box>
  );
};
  
export default Contact;