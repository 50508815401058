import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "poppins",
      textTransform: "none",
    },
  },
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       "& .MuiInputBase-input": {
    //         color: "white",
    //       },
    //       "& .MuiOutlinedInput-notchedOutline": {
    //         borderColor: "white",
    //       },
    //       "& .MuiInputLabel-root": {
    //         color: "white",
    //       },
    //       "& label.Mui-focused": {
    //         color: "white",
    //       },
    //       "& .MuiInput-underline:after": {
    //         borderBottomColor: "white",
    //       },
    //       "& .MuiOutlinedInput-root": {
    //         "& fieldset": {
    //           borderColor: "white",
    //         },
    //         "&:hover fieldset": {
    //           borderColor: "white",
    //         },
    //         "&.Mui-focused fieldset": {
    //           borderColor: "white",
    //         },
    //       },
    //     },
    //   },
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     input: {
    //       "&:-webkit-autofill": {
    //         "-webkit-box-shadow": "0 0 0 100px var(--primary-weak) inset",
    //         "-webkit-text-fill-color": "var(--text-primary)",
    //       },
    //     },
    //   },
    // },
  },
});

export default theme;
