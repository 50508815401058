import React from 'react';
import BenefitsCard from '../components/BenefitsCard';
// import CallToActionDetails from '../components/CallToActionDetails';
import LandingPage from '../components/LandingPage';
import VideoPlayer from '../components/VideoPlayer';
import { Box } from '@mui/material';
  
const Home = () => {
  return (
    <Box sx={{backgroundColor: "rgb(223 201 234)"}}>
      <LandingPage />
      <VideoPlayer />
      <BenefitsCard />
      {/* <CallToActionDetails /> */}
    </Box>
  );
};
  
export default Home;