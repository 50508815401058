import { Box } from "@mui/material";
import React, { useState } from "react";
import "./style.css"

const VideoPlayer = () => {
  const videoUrl = "https://growwx-test-bucket.s3.amazonaws.com/melody";
  const videoContainerStyle = {
    borderRadius: '15px',
    overflow: 'hidden', // This ensures the video content doesn't go beyond the rounded corners
    cursor: 'pointer', 
    display: "flex", 
    justifyContent: "center",
    width: "90vw",
    margin: "0 auto 7rem",
  };

  return (
      <Box sx={{
        marginTop:{xs: "22rem", md: "0rem", lg: "0rem"}, backgroundColor: "#dfc9ea"}}>
    <div style={videoContainerStyle}>

      <video controls className="responsive-video">
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    </Box>
  );
};

export default VideoPlayer;
