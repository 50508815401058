import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        width: "90%",
        height: "fit-content",
        textAlign: "center",
        margin: "2rem auto",
        padding: "2rem 0",
        backgroundColor: "primary.dark",
        background:
          "linear-gradient(0deg, rgba(244, 249, 255, 0) 0%, #F4F9FF 100%)",
        // backdropFilter: "blur(20px)",
        borderRadius: "20px",
        boxShadow: "0 8px 32px 0 rgb(59 72 255 / 11%)",
      }}
    >
      <Box
        sx={{
          height: "inherit",
          width: "inherit",
          textAlign: "initial",
          margin: "auto",
          // backgroundColor: "#cbe4de33",
          color: "black",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <Typography variant="h4" fontWeight={700} gutterBottom>
          <span style={{ color: "#074DC0" }}>Privacy Policy – </span>
        </Typography>
        <Typography variant="h6" sx={{ margin: "auto", fontSize:"1rem" }} gutterBottom>
          At Apik ( part of GrowwX technologies Private Limited) (“Company”,
          “we” and “us”), your privacy is important to us and we ensure that
          none of your personal information is mis-used. Below is a detailed
          Privacy Policy our company conforms to.
        </Typography>
        <List>
          <ListItem>
            <ListItemText>
              1. INTRODUCTION
              <List>
                <ListItem>
                  <ListItemText>
                    1. This privacy policy (“Privacy Policy”), read together
                    with the terms of use located at constitutes a legal and
                    binding agreement (“Agreement”) between You (hereinafter
                    referred to as “You”, or “Your” or “User(s)” and GrowwX
                    Technologies Private Limited (the “Company”), having its
                    registered office at (“C-3, First Floor, Panchvati Colony,
                    Azadpur , Delhi - 110033”) and provides, inter alia, the
                    terms that govern Your access and use of the Company’s
                    product for facilitating online payments, for a faster,
                    smoother, one-click checkout from a merchant’s website and
                    driving store conversion.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    2. The Company provides You with an option of using a
                    payment interface, the Company, at the time of check-out on
                    the merchant’s website, to facilitate quicker online
                    payments and transactions. The data shared by You will be
                    used by the Company to facilitate a faster checkout through
                    the Company. The Company ensures the protection of your
                    personal data and information by implementing strict
                    contractual and technical measures to protect the
                    confidentiality of your personal data and information by
                    taking online payments, for a faster, smoother, one-tap
                    checkout from a merchant’s website.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    3. This Privacy Policy describes the Company’s policies and
                    procedures the collection and procedures on the collection,
                    use, and disclosure of the information provided by the users
                    of the Company (together referred to as the “Users''). The
                    Company shall not use the User’s information in any manner
                    except as provided under this Privacy Policy. Every User who
                    accesses or uses the Company agrees to be bound by the terms
                    of this Privacy Policy and terms of service.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    4. The Company checkout option records and retains the
                    information You provide when You use this option to check
                    out on the merchant’s website and pay using the payment
                    gateway services supported by the Company. You generally
                    provide (a) Your name, phone number, and address; (b)
                    purchase-related information pertaining to the order; (c)
                    Device IP address and browser user agent information (d) UTM
                    and Analytical data attached to your browser session. The
                    said information collected from the users could be
                    categorized as “Personal Information”, or “Sensitive
                    Personal Information”. Personal Information, Sensitive
                    Personal Information, and Associated Information shall have
                    the meaning as individually defined under the Information
                    Technology Regulation.
                  </ListItemText>
                  <ListItemText></ListItemText>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              2. COLLECTION OF DATA
              <List>
                <ListItem>
                  <ListItemText>
                    We receive and store your information when you interact/
                    make a purchase with any merchant that uses the services of
                    The Company. Certain information may be required to register
                    with us or to take advantage of some of our features. We may
                    communicate with you if you’ve provided us with the means to
                    do so. For example, if you’ve given us your email address,
                    we may send you promotional email offers on behalf of The
                    Company or other businesses, or email you about your use of
                    the Services. Also, we may receive a confirmation when you
                    open an email from us. This confirmation helps us make our
                    communications with you more interesting and improve our
                    services.
                    <List>
                      <ListItem>
                        <ListItemText>
                          1. As part of the Company check-out services, the
                          Company will collect the following Personal
                          Information from the Users (i) name, (ii) phone
                          number, (iii) address, (iv) purchase order information
                          including item name, items stock keeping unit (“SKU”)
                          shipping address and billing address (v) IP address of
                          the device, (vi) the browser being used by the User
                          while using the Company browser user agent of the
                          device (vii) email address (viii) UTM and Analytical
                          sessions information attached to your browser session,
                          and (ix) other information as the User may provide.
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>
                          2. By using the option of the Company checkout, You
                          are expressly consenting to its collection,
                          processing, storing, disclosing, and handling of Your
                          information as set forth in this Privacy Policy now
                          and as amended by the Company processing, Your
                          information in any way, including, but not limited to,
                          collecting, storing, deleting, using, combining,
                          sharing, transferring and disclosing information, all
                          of which activities will take place in India. If You
                          reside outside India Your information will be
                          transferred, processed, and stored in accordance with
                          the applicable data protection laws of India.
                        </ListItemText>
                      </ListItem>
                    </List>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    We do not rent or sell your Personal Information in
                    personally identifiable form to anyone. However, we may
                    choose to buy or sell assets and may share and/or transfer
                    account, contact, and other Personal Information in
                    connection with the evaluation of and entry into such
                    transactions. Also, if we (or our assets) are acquired, or
                    if we go out of business, enter bankruptcy, or go through
                    some other change of control, Personal Information could be
                    one of the assets transferred to or acquired by a third
                    party.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    We may de-identify your Personal Information so that you are
                    not identified as an individual, and provide that
                    information to our partners. We may also provide aggregate
                    usage information to our partners (or allow partners to
                    collect that information from you), who may use such
                    information to understand how often and in what ways people
                    use our Services, so that they, too, can provide you with an
                    optimal online experience. In some circumstances, we employ
                    other companies and people to perform tasks on our behalf
                    and need to share your information with them to provide
                    products or services to you; for example, we may use a
                    third-party mail management service to send you emails on
                    our behalf. Unless we tell you differently, our agents do
                    not have any right to use the Personal Information we share
                    with them beyond what is necessary to assist us. We reserve
                    the right to access, read, preserve, and disclose any
                    information that we reasonably believe is necessary to
                    comply with law or court order; enforce or apply this
                    Privacy Policy and other agreements; or protect the rights,
                    property, or safety of the Company, our employees, our
                    users, or others.
                  </ListItemText>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText>
              3. COOKIES
              <ListItem>
                <ListItemText>
                  Cookies are files with a small amount of data, which may
                  include an anonymous unique identifier. Cookies are sent to
                  your browser from a website and stored on your computer's hard
                  drive.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  We use "cookies" to collect information. You can instruct your
                  browser to refuse all cookies or to indicate when a cookie is
                  being sent. However, if you do not accept cookies, you may not
                  be able to use some portions of our Service.
                </ListItemText>
              </ListItem>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              4. ACCESS OF INFORMATION
              <ListItem>
                <ListItemText>
                  This Privacy Policy covers our treatment of personally
                  identifiable information (“Personal Information”) that we
                  gather when you are accessing or using our Services, but not
                  to the practices of companies we don’t own or control, or
                  people that we don’t manage. We gather various types of
                  Personal Information from our users, as explained in more
                  detail below, and we use this Personal Information internally
                  in connection with our Services, including to personalize,
                  provide, and improve our services, to contact you, and allow
                  other users to contact you, to fulfill your requests for
                  certain products and services, and to analyze how you use the
                  Services. In certain cases, we may also share some Personal
                  Information with third parties, but only as described below.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  1. All the information, including Personal Information,
                  provided by the Users (the“User Information”) is maintained by
                  the Company in electronic form on online servers/cloud systems
                  and shall be accessible by certain employees of the Company as
                  mentioned below. The User Information may also be converted to
                  physical form from time to time. Regardless of the manner of
                  storage, the Company will keep all User Information
                  confidential, and will only disclose User Information to the
                  persons mentioned below: - Its employees are on a need-to-know
                  basis. All the Company employees and data processors, who have
                  access to, and are associated with the processing of User
                  Information, are obliged to respect the confidentiality of
                  User Information. - Third-party service providers only to the
                  extent necessary to provide the Users with the Company
                  check-out service. The Company may provide the User
                  Information to third-party service providers to work on behalf
                  of or with the Company to provide the Users with the Company
                  checkout service who will have rights to use and disclose the
                  User Information collected in connection with the provision of
                  these services in accordance with their own privacy policies.
                  The Company does not provide any Personal Information to
                  advertisers for promotional purposes.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  2. Notwithstanding the above, the Company shall not be
                  responsible for any breach of security or for any actions of
                  any third parties that receive the User Information or events
                  that are beyond the reasonable control of the Company
                  including, acts of government, computer hacking, unauthorized
                  access to computer data and storage device, computer crashes,
                  breach of security and encryption, etc.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  3. The Company may access, preserve and disclose any User
                  Information in order to (i) comply with law enforcement or
                  national security requests (ii) protect Yours’, Ours’ or
                  others’ rights, property, or safety; (iii) enforce the
                  Company’s policies or contracts; (iv) to prevent physical harm
                  or financial loss or in connection with an investigation or
                  prosecution of suspected or actual illegal activity; or (v) to
                  carry out actions that the Company believes to be necessary or
                  advisable.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  4. If the Company undergoes any merger, acquisition,
                  reorganization, or bankruptcy then the User Information may be
                  sold or transferred as part of such a transaction as permitted
                  by law and/or contract. It shall be transferred, processed,
                  and stored in accordance with the applicable data protection
                  laws of India.
                </ListItemText>
              </ListItem>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              5. PRIVACY STATEMENTS
              <ListItem>
                <ListItemText>
                  1. A condition of each User’s use of and access to the Company
                  checkout services is their acceptance of the Terms of Use,
                  which also involves acceptance of the terms of this Privacy
                  Policy. Any User that does not agree with any provisions of
                  the same is required to not avail the Company for checking out
                  and other transactions.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  2. The company uses an outside payment gateway company to
                  provide you with payment options for the products purchased by
                  You. These companies do not retain, share, store or use
                  personally identifiable information belonging to You for any
                  other purpose than for providing the payment-related services
                  and the Company does not take any responsibility and liability
                  with respect to the use of such personally identifiable
                  information whatsoever.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  3. The Company may retain records of Personal Information
                  received from the Users for the purpose of (i) administration
                  of the checkout process, (ii) rectifying any error (iii)
                  research and development, (iv) business development, and (v)
                  using such Personal Information to contact the User to provide
                  details about the Services or (vi) for User administration.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  4. The Company has implemented Indian legal industry standard
                  security policies, rules, and technical measures to protect
                  the personal data that it has under its control from
                  unauthorized access, improper use or disclosure, unauthorized
                  modification, and unlawful destruction or accidental loss. It
                  is expressly stated that the Company shall not be responsible
                  for any breach of security or for any actions of any third
                  parties that receive the Users’ personal data or events that
                  are beyond the reasonable control of the Company including,
                  acts of government, computer hacking, unauthorized access to
                  computer data and storage device, computer crashes, breach of
                  security and encryption, etc.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  5. The Company will not disclose any Personal Information or
                  data to governmental institutions or authorities unless such
                  disclosure is requisitioned under any Indian law or judicial
                  decree or when the Company, in its sole discretion, deems it
                  necessary in order to protect its rights or the rights of
                  others, to prevent harm to persons or property, to fight fraud
                  and credit risk, or to enforce or apply our Terms of Use.
                </ListItemText>
              </ListItem>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              6. USE OF PERSONAL INFORMATION
              <ListItem>
                <ListItemText>
                  By using or accessing our website or of the services offered
                  by either us directly or our clients, in any manner, you
                  acknowledge that you accept the practices and policies
                  outlined in this Privacy Policy, and you hereby consent that
                  we will collect, use, and share your information in the
                  following ways.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  The Company will use your Personal Information for
                  facilitating a faster checkout from a merchant’s portal
                  through the Company and for other administrative purposes.
                  Specifically, the data shared by You will be used to:
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  1. Auto-populate the address and shipping information on
                  checkout after completion of the mobile number authentication
                  process 2. Retain the last payment method used by the User for
                  their earlier checkout 3. For administrative purposes such as
                  making improvements to the checkout experience by detecting
                  and preventing cyber threats, frauds, and other quality
                  control purposes, responding to any questions, comments, and
                  requests for customer support, and generally administering the
                  Company services
                </ListItemText>
              </ListItem>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              7. CHANGE TO PRIVACY POLICY The Users acknowledge that this
              Privacy Policy is part of the Terms of Use and unconditionally
              agree that becoming a User of the Company signifies their assent
              to this Privacy Policy. The user’s visit and any dispute over
              privacy are subject to this policy and Terms of Use. The Company
              may update this Privacy Policy at any time, with or without
              advance notice. If a User uses the Service after changes have been
              made to the Privacy Policy, such User hereby provides his/her/its
              consent to the changed practices.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              8. USERS OBLIGATIONS
              <ListItem>
                <ListItemText>
                  1. You must, at all times, adhere to the Terms of Use and this
                  Privacy Policy. This includes not misusing all intellectual
                  property rights belonging to the Company or third parties.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  2. You must not create or otherwise disseminate any
                  information which is incorrect, false, stolen, or detrimental
                  to any other User.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  3. Any violation of this Privacy Policy may lead to the
                  restriction, suspension, or termination of Your access to the
                  Company by the Company, as we take these principles seriously
                  and consider them to be the basis on which our Users adhere to
                  the Company policies and the Services which it offers.
                </ListItemText>
              </ListItem>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              9. DATA RETENTION
              <ListItem>
                <ListItemText>
                  The Company reserves the right to retain the User Information
                  received by it and described in this Privacy Policy for as
                  long as You use the Company or as necessary to fulfill the
                  purpose(s) for which it was collected, to resolve disputes,
                  conduct audits, enforce agreements, and comply with applicable
                  laws.
                </ListItemText>
              </ListItem>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              10. DISCREPANCY AND GRIEVANCE REDRESSAL
              <ListItem>
                <ListItemText>
                  If you have any questions or concerns regarding our privacy
                  policies, please send us a detailed message at care@apik.ai
                  AND ankit@apik.ai and we will try to resolve your concerns.
                </ListItemText>
              </ListItem>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              11. INTERNATIONAL TRANSFER
              <ListItem>
                <ListItemText>
                  Your information, including Personal Information, may be
                  transferred to and maintained on computers located outside of
                  your state, province, country, or other governmental
                  jurisdiction where the data protection laws may differ from
                  those of your jurisdiction.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  If you are located outside India and choose to provide
                  information to us, please note that we transfer the
                  information, including Personal Information, to India and
                  process it there.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer.
                </ListItemText>
              </ListItem>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              12. LINKS TO OTHER SITES
              <ListItem>
                <ListItemText>
                  Our Service may contain links to other sites that are not
                  operated by us. If you click on a third-party link, you will
                  be directed to that third party's site. We strongly advise you
                  to review the Privacy Policy of every site you visit.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  We have no control over and assume no responsibility for the
                  content, privacy policies, or practices of any third-party
                  sites or services.
                </ListItemText>
              </ListItem>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              13. CHILDREN'S PRIVACY
              <ListItem>
                <ListItemText>
                  Our Service does not address anyone under the age of 13
                  ("Children").
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  We do not knowingly collect personally identifiable
                  information from children under 13. If you are a parent or
                  guardian and you are aware that your Children have provided us
                  with Personal Information, please contact us. If we become
                  aware that we have collected Personal Information from
                  children under the age of 13 without verification of parental
                  consent, we take steps to remove that information from our
                  servers.
                </ListItemText>
              </ListItem>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
