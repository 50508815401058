import CallToAction from "./components/CallToAction";
import ContactForm from "./components/ContactForm";
import ReduceRto from "./components/FeatureDetails/ReduceRto";
import SingleClickCheckout from "./components/FeatureDetails/SingleClickCheckout";
import UpliftPrepaidOrders from "./components/FeatureDetails/UpliftPrepaidOrders";
import LandingPage from "./components/LandingPage";
import CustomerReview from "./components/FeatureDetails/CustomerReview";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./assets/theme";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundsAndCancellation from "./pages/RefundsAndCancellation";
import TermsAndCondition from "./pages/TermsAndCondition";
import About from "./pages/About";
import { Box } from "@mui/material";
import ScrollToTop from "./helpers/ScrollToTop";
// import ScrollToTop from "./helpers/ScrollToTop";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            justifyContent: "space-between",
          }}
        >
          <Router>
          <ScrollToTop />
            <Header />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/refunds-and-cancellation" element={<RefundsAndCancellation />} />
            </Routes>
            <Footer />
          </Router>
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;

// CSS color for
// color: #6716CC;
// background-image: -webkit-linear-gradient(0deg, #6716CC 1%,#074DC0 100%);
// background-clip: text;
// -webkit-background-clip: text;
// text-fill-color: transparent;
// -webkit-text-fill-color: transparent;
