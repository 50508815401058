import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";

const TermsAndCondition = () => {
  return (
    <Box
      sx={{
        width: "90%",
        height: "fit-content",
        textAlign: "center",
        margin: "2rem auto",
        padding: "2rem 0",
        backgroundColor: 'primary.dark',
        background: "linear-gradient(0deg, rgba(244, 249, 255, 0) 0%, #F4F9FF 100%)",
        // backdropFilter: "blur(20px)",
        borderRadius: "20px",
        boxShadow: "0 8px 32px 0 rgb(59 72 255 / 11%)",
      }}
    >
      <Box
        sx={{
          height: "inherit",
          width: "inherit",
          textAlign: "initial",
          margin: "auto",
          // backgroundColor: "#cbe4de33",
          color: "black",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <Typography variant="h4" fontWeight={700} gutterBottom>
          <span style={{ color: "#074DC0" }}>Terms of Service – </span>
        </Typography>
        <Typography variant="h6" sx={{ margin: "auto", fontSize:"1rem" }} gutterBottom>
        ApiK’s ( whole owned by GrowwX technologies private limited) website (“Website”) access are subject to the following terms of use (the “Terms”) and notices. By using the Website you are agreeing to all of the Terms, as may be updated by us from time to time.        </Typography>
        <List>
      <ListItem>
        <ListItemText primary="" />
      </ListItem>
      <ListItem>
        <ListItemText primary="As these Terms are a legal contract between you (the “User/You/Your”) and GrowwX technologies private limited, its subsidiaries, licensees, and affiliated companies (collectively, “Apik/We”), it is important that you review the Terms carefully before accessing or using the Services. These Terms are not intended to alter in any way or limit the terms or conditions of any other agreement that you may have with Apik, including without limitation the privacy policy (“Privacy Policy”)" />
      </ListItem>
      <ListItem>
        <ListItemText primary="1.USER ACCOUNT" />
      </ListItem>
      <ListItem>
        <ListItemText primary="1.1. In order to use the Website and the Services, you are required to provide various information about yourself including your name, email address and other personal information. You agree that any information you provide to Apik on the Website will always be accurate, correct and up to date. You shall not impersonate someone else or provide account information, an email address or any other information that is not your own." />
      </ListItem>
      <ListItem>
        <ListItemText primary="1.2. You agree that all information provided by you with respect to your Apik account is accurate and up to date, failing which Apik reserves the right to suspend or terminate your account." />
      </ListItem>
      <ListItem>
        <ListItemText primary="1.3. We may upgrade, update, or modify the provision of all or any part of the Services from time to time, including to improve the Website or for other business or regulatory reasons (“Updates”). Unless mentioned otherwise, such Updates are also subject to this Agreement. In the event you choose not to continue with an Update, your sole remedy shall be to terminate this Agreement and your use of the Services." />
      </ListItem>
      <ListItem>
        <ListItemText primary="2.OWNERSHIP OF THE WEBSITE" />
      </ListItem>
      <ListItem>
        <ListItemText primary="2.1. All right, title and interest in and to all of our inventions, copyrights, trademarks, domain names, trade secrets, know-how and other intellectual property and/or proprietary rights in or related to the Services, including the Website, and any part of it (together, the “GrowwX Intellectual Property Rights”), are owned or licensed by and shall remain exclusively with GrowwX technologies. Apik is the owner or the licensee of all Apik Intellectual Property Rights. You agree that you shall not use any part of the Apik Intellectual Property Rights other than in keeping with the provisions of this Agreement, nor do you not have any right, title to or interest in the same." />
      </ListItem>
      <ListItem>
        <ListItemText primary="2.2. By using the Services, you grant Apik license to access, disclose, process, transmit and use your Data for providing the Services. We claim no intellectual property rights over the Data. For the purposes of this Agreement, “Data” shall mean all electronic data, text OWNERSHIP OF THE WEBSITE" />
  </ListItem>
  <ListItem>
    <ListItemText
      primary="2.1. All right, title and interest in and to all of our inventions, copyrights, trademarks, domain names, trade secrets, know-how and other intellectual property and/or proprietary rights in or related to the Services, including the Website, and any part of it (together, the “GrowwX Intellectual Property Rights”), are owned or licensed by and shall remain exclusively with GrowwX technologies. Apik is the owner or the licensee of all Apik Intellectual Property Rights. You agree that you shall not use any part of the Apik Intellectual Property Rights other than in keeping with the provisions of this Agreement, nor do you not have any right, title to or interest in the same."
    />
  </ListItem>
  <ListItem>
    <ListItemText
      primary="2.2. By using the Services, you grant Apik license to access, disclose, process, transmit and use your Data for providing the Services. We claim no intellectual property rights over the Data. For the purposes of this Agreement, “Data” shall mean all electronic data, text, messages or other materials, including registration data, payment and personal data of your end-customers and users, as submitted to Apik through such end-users use of our Services as embedded in your website/platform. For a detailed description of what data we collect and how we use it, please refer to our privacy policy."
    />
  </ListItem>
  <ListItem>
    <ListItemText
      primary="2.3. The Apik name and logo are trademarks of Apik, and may not be copied, imitated or used, in whole or in part, without the prior written permission of Apik, except with prior consent. In addition, all custom graphics, button icons and scripts are service marks, trademarks and/or trade dress of Apik, and may not be copied, imitated or used, in whole or in part, without prior written permission from Apik."
    />
  </ListItem>
  <ListItem>
    <ListItemText primary="3. CONDUCT ON THE WEBSITE" />
  </ListItem>
  <ListItem>
    <ListItemText
      primary="3.1. You may use the Website for lawful purposes only. You shall not post or transmit through the Website any material which violates or infringes the rights of others, or which is threatening, abusive, defamatory, invasive of privacy or publicity rights, vulgar, obscene, profane or otherwise objectionable, contains injurious formulas, recipes, or instructions, which encourages conduct that would constitute a criminal offence, give rise to civil liability or otherwise violate any law."
    />
  </ListItem>
  <ListItem>
    <ListItemText primary="4. PRIVACY AND SECURITY" />
  </ListItem>
  <ListItem>
    <ListItemText
      primary="4.1. You understand that by using the Website you consent to the collection, use and disclosure of your personally identifiable information and aggregate data as set forth in our Privacy Policy, and to have your personally identifiable information collected, used, processed and transferred to such service providers or affiliates as detailed under the thereunder."
    />
  </ListItem>
  <ListItem>
    <ListItemText
      primary="4.2. You understand that Apik cannot guarantee that unauthorized third parties will never be able to defeat our security measures or use your personal information for improper purposes. You acknowledge that you provide your personal information at your own risk."
    />
  </ListItem>
  <ListItem>
    <ListItemText
      primary="Any notice required or permitted to be given to GrowwX hereunder shall be in writing and CONDUCT ON THE WEBSITE" />
      </ListItem>
      <ListItem>
        <ListItemText primary="3.1. You may use the Website for lawful purposes only. You shall not post or transmit through the Website any material which violates or infringes the rights of others, or which is threatening, abusive, defamatory, invasive of privacy or publicity rights, vulgar, obscene, profane or otherwise objectionable, contains injurious formulas, recipes, or instructions, which encourages conduct that would constitute a criminal offence, give rise to civil liability or otherwise violate any law." />
      </ListItem>
      <ListItem>
        <ListItemText primary="4. PRIVACY AND SECURITY" />
      </ListItem>
      <ListItem>
        <ListItemText primary="4.1. You understand that by using the Website you consent to the collection, use and disclosure of your personally identifiable information and aggregate data as set forth in our Privacy Policy, and to have your personally identifiable information collected, used, processed and transferred to such service providers or affiliates as detailed under the thereunder." />
      </ListItem>
      <ListItem>
        <ListItemText primary="4.2. You understand that Apik cannot guarantee that unauthorized third parties will never be able to defeat our security measures or use your personal information for improper purposes. You acknowledge that you provide your personal information at your own risk." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Any notice required or permitted to be given to GrowwX hereunder shall be in writing and sent or transmitted by (i) registered or certified mail; (ii) hand-delivery; (iii) email; or (iv) internationally recognized courier service, provided its receipt is acknowledged and, dispatched or sent or transmitted to the address specified:" />
      </ListItem>
      <ListItem>
        <ListItemText primary="GrowwX Technologies private limited" />
      </ListItem>
      <ListItem>
        <ListItemText primary="C-3, First Floor," />
      </ListItem>
      <ListItem>
        <ListItemText primary="Panchvati Colony," />
      </ListItem>
      <ListItem>
        <ListItemText primary="Azadpur," />
      </ListItem>
      <ListItem>
        <ListItemText primary="Delhi - 110033" />
      </ListItem>
      <ListItem>
        <ListItemText primary="email: care@apik.ai" />
      </ListItem>
    </List>
      </Box>
    </Box>
  );
};

export default TermsAndCondition;
