import * as React from "react";
import Box from "@mui/material/Box";
import { Button, TextField, Typography } from "@mui/material";

export default function LandingPage() {
  const [formData, setFormData] = React.useState({
    name: "",
    phone: "",
    email: "",
    website: "",
    platform: "",
    monthlySales: "",
    kulturEarlyAccessEmail: ""
  });

  const handleChange = (e) => {
    console.log(e.target.value)
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    const response = await fetch("https://formspree.io/f/xyyapkgy", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    if (response.ok) {
      console.log("Form submitted successfully");
      setFormData({
        name: "",
        phone: "",
        email: "",
        website: "",
        platform: "",
        monthlySales: "",
      });
    } else {
      console.error("Error submitting form");
    }
  };
  // function handleScroll() {
  //   const element = document.getElementById('lets_connect');
  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }

  return (
    <Box
      sx={{
        width: "100%",
        height: "65vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "auto",
        backgroundColor: "rgb(223 201 234)",
      }}
    >
      <Box
        sx={{
          height: "fit-content",
          textAlign: "center",
          width: "80vw",
          padding: "2rem",
          margin: "0 auto",
          marginTop:{xs: "22rem", md: "0rem", lg: "0rem"},
        }}
      >
        <Typography
          variant="h1"
          gutterBottom
          fontWeight={600}
          sx={{
            fontSize: { xs: "1.9rem", md: "2rem", lg: "3rem" },
            fontWeight: { xs: "800", md: "600" }, color:"#0d1334"
          }}
        >
          <Typography
            variant="h1"
            fontWeight={600}
            sx={{
              color: "#074DC0",
              fontSize: { xs: "1.8rem", md: "2rem", lg: "2rem" },
            }}
          >
            {/* EPIC GROWTH */}
          </Typography>{" "}
          Drive Engagement, Enhance Profits, and Make Workplaces Joyful and Fair.
        </Typography>
        <Typography
          variant="h6"
          sx={{ width: { xs: "80vw", md: "75%" }, margin: "auto", fontSize: { xs: "1.1rem", md: "1.3rem", }}}
          gutterBottom
          fontWeight={400}
          
        >
           Culture has direct and significant impact on sales, profits, and employee retention, either positively or negatively. Creating and maintaining a positive culture is important for companies of all sizes. We play a direct and valuable role in using culture as a force multiplier for companies across the globe.
        </Typography>
        <Typography
          variant="h6"
          sx={{ width: { xs: "80vw", md: "75%" }, margin: "auto" }}
          gutterBottom
          fontWeight={400}
        >
          {/* We play a direct and valuable role in using culture as a force multiplier for companies across the globe. */}
        </Typography>
        <Box id="lets_connect" sx={{display:"flex", flexDirection:"row", alignItems: "center", justifyContent: "center", margin:"1rem 0", flexWrap: "wrap"}}>
        <form onSubmit={handleSubmit}>
        <TextField id="outlined-basic" label="Enter your Email" variant="outlined" style={{ width: '300px', margin: "1rem",}} onChange={handleChange} name="kulturEarlyAccessEmail" value={formData.kulturEmail} />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          sx={{
            background: "#0d1334",
            margin: "1rem",
            padding: "0.8rem 2rem",
            borderRadius: "5px",
          }}
          // onClick={}
        >
          Get Early access
        </Button>
        </form>
        </Box>
      </Box>
    </Box>
  );
}
