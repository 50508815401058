import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ApikLogo from "../../assets/logo/apik-logo.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function Header() {
  function handleScroll() {
    const element = document.getElementById('lets_connect');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "rgb(223 201 234)", padding: "1rem 0" }}
      elevation={0}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: 'flex',
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'black',
              textDecoration: 'none',
            }}
          >
            APIK
          </Typography> */}
          <Link to="/">
            <img src={ApikLogo} alt="Logo" height={28} />
          </Link>
          <br />

          {/* <Button
            variant="contained"
            size="large"
            sx={{
              backgroundColor: "#000",
              // borderRadius: "2px",
              // m: 1,
                  // p: 1.5,
              color: "#f4f9ff",
              "&:hover": { backgroundColor: "#000" },
            }}
            onClick={handleScroll}
          >
            Get Early Access
          </Button> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
