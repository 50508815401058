import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Ceo from "../../assets/img/features/ceo.png";
import Employee from "../../assets/img/features/employee.png";
import Hr from "../../assets/img/features/hr.png";
import Manager from "../../assets/img/features/manager.png";

export default function BenefitsCard() {
  return (
    <Box
      sx={{
        width: { xs: "100%", md: "100%" },
        height: "fit-content",
        textAlign: "center",
        // margin: "auto",
        margin: { xs: "2rem auto 0rem", md: "0rem auto 0rem" },
        backgroundColor: "white",
        // boxShadow: "0 8px 32px 0 rgb(59 72 255 / 11%)",
      }}
    >
      {/* <Typography sx={{color:"white", paddingTop:"1rem", textAlign:"left", marginLeft:"2rem"}} style={{fontSize: "2.2rem", fontWeight: "600"}}>We Solve For</Typography> */}
      <Box
        sx={{
          height: "inherit",
          textAlign: "center",
          // margin: "0 auto 2rem",
          // backgroundColor: "#cbe4de33",
          color: "black",
          borderRadius: "10px",
          padding: { xs: "1rem 0rem 4rem", md: "3rem 1rem" },
        }}
      >
        <Box sx={{ position: "relative", padding: "5.5rem 0 5rem" }}>
          <Box
            sx={{
              position: "relative",
              height: "25px",
              width: { xs: "90%", md: "40%" },
              backgroundColor: "#FDCEDB",
              margin: "0 auto",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "1.5rem", md: "2.5rem" },
                fontWeight: "600",
                textAlign: "center",
                transform: { sx: "translateY(-16px)", md: "translateY(-32px)" },
              }}
            >
              The key issues we solve
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: { xs: "90%", md: "30%" },
              backgroundColor: "rgb(204 228 185)",
              height: "18rem",
              m: { xs: 2, md: 6 },
              p: 4,
              borderRadius: "6px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <img src={Ceo} alt="Logo" height={60} />
              <Typography
                style={{
                  fontWeight: "600",
                  marginLeft: "1rem",
                  textAlign: "left",
                }}
                sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
              >
                CXO's
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "black",
                transform: "translateY(0.8rem)",
                textAlign: "left",
              }}
            >
              100% of CEO and CHRO’s want to drive employee engagement. However,
              they lack any leading indicator to measure engagement or act in
              timely manner. They also lack any objective way to measure RoI on
              culture.
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "95%", md: "30%" },
              backgroundColor: "rgb(204 228 185)",
              height: "18rem",
              m: { xs: 2, md: 6 },
              p: 4,
              borderRadius: "6px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <img src={Employee} alt="Logo" height={60} />
              <Typography
                style={{
                  fontWeight: "600",
                  marginLeft: "1rem",
                  textAlign: "left",
                }}
                sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
              >
                Employees
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "black",
                transform: "translateY(0.8rem)",
                textAlign: "left",
              }}
            >
              80% of the employees are disengaged at work today. An actively
              disengaged employee lowers the productivity, impacts the team
              morale and can bring the business down.
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "95%", md: "30%" },
              backgroundColor: "rgb(204 228 185)",
              height: "18rem",
              m: { xs: 2, md: 6 },
              p: 4,
              borderRadius: "6px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <img src={Manager} alt="Logo" height={60} />
              <Typography
                style={{
                  fontWeight: "600",
                  marginLeft: "1rem",
                  textAlign: "left",
                }}
                sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
              >
                Managers
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "black",
                transform: "translateY(0.8rem)",
                textAlign: "left",
              }}
            >
              None of the existent products and tools help Managers and HRs to
              identify the problem and then solve it as well. There is a
              complete lack of closed loop and data driven systems that can
              drive action and ownership.
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "95%", md: "30%" },
              backgroundColor: "rgb(204 228 185)",
              height: "18rem",
              m: { xs: 2, md: 6 },
              p: 4,
              borderRadius: "6px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <img src={Hr} alt="Logo" height={60} />
              <Typography
                style={{
                  fontWeight: "600",
                  marginLeft: "1rem",
                  textAlign: "left",
                }}
                sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
              >
                HRBP
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "black",
                transform: "translateY(0.8rem)",
                textAlign: "left",
              }}
            >
              Employees do not have any trusted and anonymous system that can
              objectively and empathetically gather their feedback. The current
              HRMS and point-in-time surveys, and AI Chatbots do not listen
              regularly or intelligently.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
