import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ApikLogo from "../../assets/logo/apik_white_logo.png";

export default function Footer() {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box sx={{ bgcolor: "black", color: "white", padding:"6rem 3rem 4rem" }} component="footer">
      <Link to="/">
              <img src={ApikLogo} alt="Logo" height={28} />
            </Link>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          
          <Box sx={{ width: { xs: "90%", md: "40%" } }}>
            
            <Typography
              variant="h6"
              sx={{ fontWeight: "300", fontSize: "1.1rem" }}
              gutterBottom
            >
              Kultur creates unique solution based on SAAS and AI improve culture. It helps to improve sales, increase profits, and ensure employee retention.
            </Typography>
          </Box>
          <Box sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            width:{md:"50%"}
          }}>
            <Box sx={{ margin: { xs: "1rem 0", md: "0rem" } }}>
              {/* <Typography variant="subtitle1" sx={{fontWeight:"600"}} component="p">
                LINKS
              </Typography>
              <Link
                style={{ textDecoration: "none", color: "#86878A" }}
                to="/"
              >
                Home
              </Link>
              <br />
              <Link
                style={{ textDecoration: "none", color: "#86878A" }}
                to="/about"
              >
                About
              </Link>
              <br />
              <Link
                style={{ textDecoration: "none", color: "#86878A" }}
                to="/contact"
              >
                Contact
              </Link> */}
              <br />
              {/* <Link style={{textDecoration: "none", color: "white"}}  to="/privacy-policy">Privacy Policy</Link><br />
            <Link style={{textDecoration: "none", color: "white"}}  to="/terms-and-conditions">Terms and Condition</Link><br />
            <Link style={{textDecoration: "none", color: "white"}}  to="/refunds-and-cancellation">Refunds and cancellation</Link><br /> */}
            </Box>

            <Box sx={{ margin: { xs: "1rem 0", md: "0rem" } }}>
              <Typography variant="subtitle1" sx={{fontWeight:"600"}} component="p">
                CONTACT US
              </Typography>
              <Typography variant="subtitle1" sx={{color: "#86878A"}} component="p">
                Write us at : care@apik.ai
              </Typography>
              <Typography variant="subtitle1" sx={{color: "#86878A"}} component="p">
                Call us at : +919899733577
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ textAlign: "center", marginTop: "3rem" }}
            component="p"
          >
            © 2023 GrowwX Technology Private Limited 
            &nbsp;&#183;&nbsp;<Link
              style={{ textDecoration: "none", color: "white" }}
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
            &nbsp;&#183; &nbsp;
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/terms-and-conditions"
            >
              Terms and Condition
            </Link>
            &nbsp;&#183;&nbsp;
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/refunds-and-cancellation"
            >
              Refunds and cancellation
            </Link> &nbsp;
            
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
