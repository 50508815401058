import { Box, List, ListItem, ListItemText, ListSubheader, Typography } from "@mui/material";
import React from "react";

const RefundsAndCancellation = () => {
  return (
    <Box
      sx={{
        width: "90%",
        height: "fit-content",
        textAlign: "center",
        margin: "2rem auto",
        padding: "2rem 0",
        backgroundColor: "primary.dark",
        background:
          "linear-gradient(0deg, rgba(244, 249, 255, 0) 0%, #F4F9FF 100%)",
        // backdropFilter: "blur(20px)",
        borderRadius: "20px",
        boxShadow: "0 8px 32px 0 rgb(59 72 255 / 11%)",
      }}
    >
      <Box
        sx={{
          height: "inherit",
          width: "inherit",
          textAlign: "initial",
          margin: "auto",
          // backgroundColor: "#cbe4de33",
          color: "black",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <Typography variant="h4" fontWeight={700} gutterBottom>
          <span style={{ color: "#074DC0" }}>Refunds & Cancellations – </span>
        </Typography>
        <Typography variant="h6" sx={{ margin: "auto", fontSize:"1rem" }} gutterBottom>
        Apik (part of GrowwX technologies private limited) is only a facilitator of transactions for different merchants will honour the return and cancellation policy of the respective merchant.
        </Typography>
        <List>
      <ListItem>
        <ListItemText primary="Procedure of Refund:" />
      </ListItem>
      <ListItem>
        <ListItemText primary="- For online transactions, refunds will be credited to the source within 5-7 working days after the merchant initiates the refund." />
      </ListItem>
      <ListItem>
        <ListItemText primary="- For offline transactions, merchants will be solely responsible for the refund" />
      </ListItem>
      <ListItem>
        <ListItemText primary="In either case, merchants will be responsible for carrying out refunds and managing cancellations. If you have any questions, please feel free to reach out to care@apik.ai and we will help you." />
      </ListItem>
    </List>
      </Box>
    </Box>
  );
};

export default RefundsAndCancellation;
