import { Box, Typography } from "@mui/material";
import React from "react";

const About = () => {
  return (
    <Box
      sx={{
        width: "90%",
        height: "fit-content",
        textAlign: "initial",
        margin: "2rem auto",
        padding: "2rem 0",
        backgroundColor: 'primary.dark',
        background: "linear-gradient(0deg, rgba(244, 249, 255, 0) 0%, #F4F9FF 100%)",
        // backdropFilter: "blur(20px)",
        borderRadius: "20px",
        boxShadow: "0 8px 32px 0 rgb(59 72 255 / 11%)",
      }}
    >
      <Box
        sx={{
          height: "inherit",
          width: "inherit",
          textAlign: "initial",
          margin: "auto",
          // backgroundColor: "#cbe4de33",
          color: "black",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <Typography variant="h4" fontWeight={700} gutterBottom>
          <span style={{ color: "#074DC0" }}>About us – </span>
        </Typography>
        <Typography
          variant="h6"
          sx={{ margin: "auto" }}
          gutterBottom
        >
          There are close to 26M+ online D2C stores across the world. Yet, most of
         the customers shop from big market-places because of higher trust and
         far better shopping experience. Today, we are reinventing the shopping
         experience of D2C online stores with the use of scalable tech. We are on
         a mission to elevate the end to end shopping journey of D2C customers
         that helps them gain trust, drive sales and repeat purchases. When you
         join our APIK family, you'll play a crucial role in shaping the future
         of retail, improving millions of lives around the world !
        </Typography>
        <br />
        <Typography
          variant="h6"
          sx={{ margin: "auto" }}
          gutterBottom
        >
           APIK team has decades of super strong experience in online commerce. The
        team is super passionate to drive bigger impact on the future of retail.
        If you are interested in being part of this mission, drop us a note on
        care@apik.ai and we'll get back to you real quick.
        </Typography>
      </Box>
    </Box>
  );
};

export default About;
